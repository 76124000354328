@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes mySpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-indicator:before {
  content: "";
  background: #00000080;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: " ";
  position: fixed;
  top: 40%;
  left: 45%;
  z-index: 10010;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #0474bf; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: mySpin 2s linear infinite;
}

.scroll-custom::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scroll-custom::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  height: 3px;
}

.scroll-custom::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
.btn-danger {
  color: rgb(249, 99, 99);
  border-color: rgb(249, 99, 99);
}
.btn-danger:hover {
  background-color: rgb(249, 99, 99);
  border-color: rgb(249, 99, 99);
  color: white;
}
